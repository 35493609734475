import { graphql } from "gatsby"
import queryString from "query-string"
import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import Layout from "../components/Layout"
import Link from "../components/Link"
import { BrinkContext } from "../components/context/BrinkContext"
import WidgetLoader from "../components/widgets/WidgetLoader"
import {
  MEDIA_MIN_LARGE,
  MEDIA_MIN_MEDIUM,
  MEDIA_MIN_X_LARGE
} from "../constants"

const DesktopContainer = styled.div`
  display: none;

  ${MEDIA_MIN_LARGE} {
    display: flex;
    flex-direction: row;
    padding: 0rem 0 8rem 25%;
    margin-bottom: 10rem !important;

    h3 {
      font-variation-settings: "wght" 500;
      font-size: 3rem;
    }

    span {
      margin: 0.5rem 0;
      text-transform: uppercase;
    }
  }

  ${MEDIA_MIN_X_LARGE} {
    padding: 0rem 0 8rem 25%;
  }
`

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% / 6);
  position: relative;
  gap: 1rem;
  top: -5rem;

  h1 {
    font-size: 2rem;
    margin: 0;
    display: inline;
  }
`

const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  margin-bottom: 5rem;

  h3 {
    font-size: 3rem;
    font-variation-settings: "wght" 500;
  }
  ${MEDIA_MIN_LARGE} {
    display: none;
  }
`

const Row = styled.div`
  width: 100%;
  border-top: 0.1rem solid ${(p) => p.theme.colors.lightGrey};
  padding: 1rem 0;
  text-transform: uppercase;
  &:last-child {
    border-bottom: 0.1rem solid ${(p) => p.theme.colors.lightGrey};
  }

  h1 {
    font-size: 2rem;
    display: inline;
    margin: 0;
  }
`
const WidgetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 60rem;
  padding-left: 2rem;

  ${MEDIA_MIN_MEDIUM} {
    margin-top: 3.5rem;
    padding-left: 0;
  }
`

const WidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5.4rem;
  margin-bottom: 16.5rem;
  ${MEDIA_MIN_LARGE} {
    margin-top: 5.4rem;
  }
`

const CmsPage = ({ data: { sanityCmsPage }, pageContext, location }) => {
  const { languageCode, setDiscountCode, addDiscount } =
    useContext(BrinkContext)
  const { widgets, pageTitle, pageDescription, cmsMenu, slug } = sanityCmsPage
  const { discountCode } = queryString.parse(location.search)
  const [activeMenuItem, setActiveMenuItem] = useState(
    cmsMenu?.[0]?.menuItems?.find(
      (menuItem) => menuItem.slug.en === slug.current
    )
  )
  useEffect(() => {
    const applyCode = async (discountCode) => {
      await addDiscount({ code: discountCode })
    }

    if (discountCode) {
      applyCode(discountCode)
      setDiscountCode(discountCode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountCode])

  return (
    <Layout
      meta={{
        title: pageTitle[languageCode] || pageTitle.en,
        description: pageDescription[languageCode] || pageDescription.en
      }}
      invertedHeader
      pageContext={pageContext}
      containsCmsMenu={cmsMenu.length >= 1}
    >
      {cmsMenu.length >= 1 ? (
        <>
          <DesktopContainer>
            {cmsMenu.map((cmsMenuSection) => {
              const { menuItems, title } = cmsMenuSection
              return (
                <Sidebar key={cmsMenuSection._id}>
                  <h3>{title?.[languageCode] || title?.en}</h3>
                  {menuItems.map((menuItem) => {
                    return (
                      <Link
                        key={menuItem.id}
                        to={`/${
                          menuItem?.slug?.[languageCode] || menuItem?.slug?.en
                        }/`}
                        color={"black"}
                      >
                        {activeMenuItem?.slug.en === menuItem?.slug.en ? (
                          <h1>
                            {"→ "}
                            {menuItem?.title[languageCode] ||
                              menuItem?.title.en}
                          </h1>
                        ) : (
                          <span>
                            {menuItem?.title[languageCode] ||
                              menuItem?.title.en}
                          </span>
                        )}
                      </Link>
                    )
                  })}
                </Sidebar>
              )
            })}

            {widgets && widgets.length && (
              <WidgetWrapper>
                <WidgetLoader widgets={widgets} cmsMenu={cmsMenu.length > 0} />
              </WidgetWrapper>
            )}
          </DesktopContainer>
          <MobileContainer>
            {cmsMenu.map((cmsMenuSection) => {
              const { menuItems, title } = cmsMenuSection
              return (
                <div key={cmsMenuSection._id}>
                  <h3>{title?.[languageCode] || title?.en}</h3>
                  {menuItems.map((menuItem) => {
                    return (
                      <Row key={menuItem.id}>
                        <Link
                          to={`/${
                            menuItem?.slug?.[languageCode] || menuItem?.slug?.en
                          }/`}
                          color={"black"}
                          onClick={() =>
                            activeMenuItem
                              ? setActiveMenuItem(null)
                              : setActiveMenuItem(
                                  cmsMenu?.[0]?.menuItems?.find(
                                    (menuItem) =>
                                      menuItem.slug.en === slug.current
                                  )
                                )
                          }
                        >
                          {activeMenuItem?.slug.en === menuItem?.slug.en ? (
                            <h1>
                              {"↑ "}
                              {menuItem?.title[languageCode] ||
                                menuItem?.title.en}
                            </h1>
                          ) : (
                            <span>
                              {"↓ "}{" "}
                              {menuItem?.title[languageCode] ||
                                menuItem?.title.en}
                            </span>
                          )}
                        </Link>
                        {activeMenuItem?.slug.en === menuItem?.slug.en
                          ? widgets &&
                            widgets.length && (
                              <WidgetWrapper>
                                <WidgetLoader
                                  widgets={widgets}
                                  cmsMenu={cmsMenu.length > 0}
                                />
                              </WidgetWrapper>
                            )
                          : null}
                      </Row>
                    )
                  })}
                </div>
              )
            })}
          </MobileContainer>
        </>
      ) : (
        <WidgetContainer>
          {widgets && widgets.length && (
            <WidgetLoader widgets={widgets} margin />
          )}
        </WidgetContainer>
      )}
    </Layout>
  )
}

export default CmsPage

export const query = graphql`
  query ($sanityPageId: String!) {
    sanityCmsPage(_id: { eq: $sanityPageId }) {
      pageTitle {
        en
        sv
        de
        fr
      }
      pageDescription {
        en
        sv
        de
        fr
      }
      slug {
        current
      }
      widgets {
        ...TextWidget
        ...Grid
        ...Hero
        ...ProductSlider
        ...UspWidget
        ...Image
        ...Video
        ...IconGrid
        ...Instruction
        ...Testimonials
        ...SunEffects
        ...MarqueeBanner
        ...TableWidget
        ...ProductShowcase
        ...MediaShowcase
        ...MediaTimer
        ...ProductReview
        ...ImageGrid
        ...SectionsWithoutAnchor
        ...CollectionGrid
        ...ReviewCarousel
        ...UspCarousel
      }
      cmsMenu {
        _id
        title {
          sv
          fr
          en
          de
        }
        menuItems {
          ... on SanityMenuItem {
            id
            slug {
              de
              en
              fr
              sv
            }
            title {
              de
              en
              fr
              sv
            }
          }
          ... on SanityMenuText {
            id
            _rawText
          }
        }
      }
    }
  }
`
